<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-plus-circle</v-icon>
        <h4 class="ml-3 mb-0">เพิ่มรายการซื้อยาและเวชภัณฑ์</h4>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-row class="pa-0 d-flex align-center">
                <v-col cols="12" sm="12" md="3" lg="3">
                  <span class="label-drudsandmedical-add-stock-incard">รหัสยา:</span>
                </v-col>
                <v-col cols="12" sm="12" md="9" lg="9">
                  <span>{{product_code}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-row class="pa-0 d-flex align-center">
                <v-col cols="12" sm="12" md="3" lg="3">
                  <span class="label-drudsandmedical-add-stock-incard">ชื่อยา:</span>
                </v-col>
                <v-col cols="12" sm="12" md="9" lg="9">
                  <span>{{product_name}}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" sm="0" md="0" lg="6"></v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">วันที่ซื้อ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-menu
                ref="menuBuyDate"
                v-model="menuBuyDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedBuyDateFormatted"
                    label="วันที่ซื้อ"
                    placeholder="ระบุวันที่ซื้อ"
                    outlined
                    single-line
                    hide-details
                    append-outer-icon="mdi-calendar-month"
                    readonly
                    @click:append-outer="menuBuyDate = true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="product_buy_date"
                  no-title
                  @change="menuBuyDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ราคาที่ซื้อ/ต่อหน่วย</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputCostPricePerUnit"
                label="ราคาที่ซื้อ/ต่อหน่วย"
                placeholder="ระบุราคาที่ซื้อ/ต่อหน่วย"
                suffix="บาท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsStockCycle"
                v-model="selectStockCycle"
                label="เลือกรอบสต๊อก"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนที่ซื้อ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                v-model="inputBuyNumber"
                label="จำนวนที่ซื้อ"
                placeholder="ระบุจำนวนที่ซื้อ"
                suffix="เส้น"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ผู้จำหน่าย</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-select
                :items="itemsDealer"
                v-model="selectDealer"
                label="เลือกผู้จำหน่าย"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-row class="pa-0 d-flex align-center">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">วันที่หมดอายุ</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-menu
                ref="menuBestBeforeDate"
                v-model="menuBestBeforeDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedBestBeforeDateFormatted"
                    label="วันที่หมดอายุ"
                    placeholder="ระบุวันที่หมดอายุ"
                    outlined
                    single-line
                    hide-details
                    append-outer-icon="mdi-calendar-month"
                    readonly
                    @click:append-outer="menuBestBeforeDate = true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="product_buy_date"
                  no-title
                  @change="menuBestBeforeDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="d-flex flex-row align-center justify-end mt-5">
        <v-btn
          color="success"
          @click="createDrugAndMedical()"
          :loading="loaderSave"
          :disabled="loaderSave"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          บันทึก
        </v-btn>
        <v-btn
          color="error"
          class="ml-3"
          @click="goBack()"
          :loading="loaderCancel"
          :disabled="loaderCancel"
        >
          <v-icon class="mr-2">mdi-close-circle</v-icon>
          ยกเลิก
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'
import moment from 'moment'

export default {
  data () {
    return {
      loaderSave: null,
      loaderCancel: null,
      product_code: '',
      product_name: '',
      product_buy_date: new Date().toISOString().substr(0, 10),
      product_buy_per_unit: 0,
      product_stock_cycle: '',
      product_buy_number: 0,
      product_dealer: '',
      product_best_before: new Date().toISOString().substr(0, 10),
      itemsStockCycle: ['L00052 (31/01/2564)'],
      itemsDealer: ['ไม่ระบุ'],
      selectStockCycle: 'L00052 (31/01/2564)',
      selectDealer: 'ไม่ระบุ',
      inputCostPricePerUnit: 100,
      inputBuyNumber: 245,
      menuBuyDate: false,
      menuBestBeforeDate: false
    }
  },
  created () {
    const self = this
    self.product_code = self.$route.params.product_code
    self.product_name = self.$route.params.product_name
  },
  computed: {
    computedBuyDateFormatted () {
      return this.product_buy_date ? moment(this.product_buy_date).format('DD/MM/YYYY') : ''
    },
    computedBestBeforeDateFormatted () {
      return this.product_best_before ? moment(this.product_best_before).format('DD/MM/YYYY') : ''
    }
  },
  methods: {
    goBack () {
      const self = this
      self.loaderSave = 'loader'
      self.loaderCancel = 'loader'

      self.product_code = ''
      self.product_name = ''
      self.product_buy_date = new Date().toISOString().substr(0, 10)
      self.product_buy_per_unit = 0
      self.product_stock_cycle = ''
      self.product_buy_number = 0
      self.product_dealer = ''
      self.product_best_before = new Date().toISOString().substr(0, 10)
      self.itemsStockCycle = []
      self.itemsDealer = []
      self.selectStockCycle = ''
      self.selectDealer = ''
      self.inputCostPricePerUnit = 0
      self.inputBuyNumber = 0
      self.menuBuyDate = false
      self.menuBestBeforeDate = false

      setTimeout(() => {
        self.$router.go(-1)
      }, 200)
    },
    createDrugAndMedical () {
      const self = this
      self.loaderSave = 'loader'
      self.loaderCancel = 'loader'

      setTimeout(() => {
        swal('สำเร็จ', 'เพิ่มรายการซื้อยาและเวชภัณฑ์สำเร็จ', 'success', {
          button: false,
          timer: 1000
        }).then(() => {
          self.loaderSave = null
          self.loaderCancel = null
          self.$router.go(-2)
        })
      }, 200)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/DrugsAndMedical_AddStock.css';
</style>
